import { AuthenticationErrorCodes } from './constants';
const maskEmail = (email) => {
    if (!(email === null || email === void 0 ? void 0 : email.length))
        return '';
    const [name, domain] = email.split('@');
    return `${name.slice(0, 2)}***${domain}`;
};
const handleResponseError = ({ translateFn, setError, error, }) => {
    if (error === null || error === void 0 ? void 0 : error.code) {
        if (AuthenticationErrorCodes.EMAIL_IS_EXIST === error.code) {
            setError('email', {
                message: translateFn('auth.sign_up.steps.credentials.email.email_errors.email_is_exist', { ns: 'common' }),
            });
        }
        else if (error.code === AuthenticationErrorCodes.INVALID_CREDENTIALS ||
            error.code === AuthenticationErrorCodes.USER_NOT_FOUND) {
            setError('email', {
                message: translateFn('auth.sign_in.steps.credentials.email.email_errors.invalid_email_or_password', { ns: 'common' }),
            });
            setError('password', {
                message: translateFn('auth.sign_in.steps.credentials.email.password_errors.invalid_email_or_password', { ns: 'common' }),
            });
        }
    }
    return error;
};
export { maskEmail, handleResponseError, };
