import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Wizard } from '@shared/ui/misc/Wizard';
import { useAppSelector } from '@app/store/Hooks';
import Confirmation from './steps/Confirmation';
import FindAccount from './steps/FindAccount';
import ResetPasswordStep from './steps/ResetPasswordStep';
const ResetPassword = () => {
    const { authStep } = useAppSelector(state => state.auth);
    return (_jsxs(Wizard, { initialStep: typeof authStep === 'number' ? authStep : undefined, children: [_jsx(FindAccount, {}), _jsx(ResetPasswordStep, {}), _jsx(Confirmation, {})] }));
};
export default ResetPassword;
