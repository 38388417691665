import * as yup from 'yup';
import { REGEXP_EMAIL, REGEXP_PASSWORD } from '@app/constants/validation';
import { checkIfNotNumeric, checkSimilarity } from '@utils/password';
const emailRegistrationValidationSchema = (translateFn) => yup.object().shape({
    email: yup.string().nullable()
        .min(1, translateFn('auth.sign_up.steps.credentials.email.email_errors.min_length', { ns: 'common' }))
        .matches(REGEXP_EMAIL, translateFn('auth.sign_up.steps.credentials.email.email_errors.incorrect_format', { ns: 'common' })),
    password: yup.string()
        .min(8, translateFn('auth.sign_up.steps.credentials.email.password_errors.min_length', { ns: 'common' }))
        .test('check-password-email-similarity', translateFn('auth.sign_up.steps.credentials.email.password_errors.similarity_error', { ns: 'common' }), (password, testContext) => { var _a; return checkSimilarity(password || '', ((_a = testContext === null || testContext === void 0 ? void 0 : testContext.parent) === null || _a === void 0 ? void 0 : _a.email) || ''); }).test('check-password-is-not-numeric', translateFn('auth.sign_up.steps.credentials.email.password_errors.not_numeric', { ns: 'common' }), (password) => checkIfNotNumeric(password || ''))
        .matches(REGEXP_PASSWORD, translateFn('auth.sign_up.steps.credentials.email.password_errors.uncorrect_chars', { ns: 'common' })),
    repeatPassword: yup.string().oneOf([yup.ref('password')], translateFn('auth.sign_up.steps.credentials.email.repeat_password_errors.not_equal', { ns: 'common' })),
    areRulesAccepted: yup.boolean(),
});
export default emailRegistrationValidationSchema;
