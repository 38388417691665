var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useState } from 'react';
import { useWizard } from '@shared/ui/misc/Wizard';
import { ContainerColumn } from '@components/styled';
import ButtonCustom from '@shared/ui/buttons/ButtonCustom';
import useAuth from '../../../../../lib/useAuth';
import { maskEmail } from '../../../../../lib/utils';
import { ResetPasswordVariants } from '../../../../../lib/constants';
import ResendEmailCountdownTimer from '../../../../../../../shared/ui/misc/ResendEmailCountdownTimer';
import FormWrapper from '../../../../components/FormWrapper';
import { Text } from '../../../../components/shared';
const Container = styled.div(props => ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 380,
    textAlign: 'center',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s * 5,
}));
const SubmitButton = styled(ButtonCustom)(props => ({
    lineHeight: '20px',
    padding: `${props.theme.spacing_sizes.xs * 1.25}px ${props.theme.spacing_sizes.m}px`,
}));
const ButtonContainer = styled(ContainerColumn)(props => ({
    gap: props.theme.spacing_sizes.m,
    width: 'max-content',
    height: 'auto',
}));
const Confirmation = () => {
    const { t } = useTranslation();
    const { goBack } = useWizard();
    const { emailForReset, closeResetForm, isResetting, obtainResetLink, } = useAuth();
    const [displayTimer, setDisplayTimer] = useState(false);
    const hideTimer = () => {
        setDisplayTimer(false);
    };
    const handleReset = () => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield obtainResetLink(ResetPasswordVariants.EMAIL);
        if (res) {
            setDisplayTimer(true);
        }
    });
    return (_jsx(FormWrapper, { titleText: t('auth.reset_password.steps.confirmation.title', { ns: 'common' }), titleSpacing: '0 0 20px 0', renderInnerForm: (_jsxs(Container, { children: [_jsx(Text, { children: t('auth.reset_password.steps.confirmation.text', { email: maskEmail(emailForReset || ''), ns: 'common' }) }), _jsxs(ButtonContainer, { children: [_jsx(SubmitButton, { variant: 'contained', size: 'medium', onClick: handleReset, isLoading: isResetting, disabled: isResetting || displayTimer, disableRipple: true, disableElevation: true, children: t('auth.reset_password.steps.confirmation.resend_link_text', { ns: 'common' }) }), displayTimer && (_jsx(ResendEmailCountdownTimer, { onTimeOver: hideTimer }))] })] })), backLinkProps: {
            text: t('auth.reset_password.steps.backlink_text', { ns: 'common' }),
            onClick: goBack,
        }, handleReset: closeResetForm, showNavigation: true }));
};
export default Confirmation;
