import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useWizard } from '@shared/ui/misc/Wizard';
import useAuth from '../../../../../lib/useAuth';
import FormWrapper from '../../../../components/FormWrapper';
import FindAccountForm from './FindAccountForm';
const FindAccount = () => {
    const { t } = useTranslation();
    const { setEmailForPasswordReset, closeResetForm, returnToSignIn } = useAuth();
    const { goNext } = useWizard();
    const handleSubmit = (email) => {
        setEmailForPasswordReset(email);
        goNext();
    };
    return (_jsx(FormWrapper, { renderInnerForm: _jsx(FindAccountForm, { onSubmit: handleSubmit }), titleText: t('auth.reset_password.steps.find_account.title', { ns: 'common' }), backLinkProps: {
            text: t('auth.reset_password.steps.backlink_text', { ns: 'common' }),
            onClick: returnToSignIn,
        }, handleReset: closeResetForm, showNavigation: true }));
};
export default FindAccount;
