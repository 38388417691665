import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Input from '@shared/ui/inputs/Input';
import { SubmitButton } from '../../../../../components/shared';
const FindAccountContainer = styled.div `
    width: inherit;
    max-width: 450px;
`;
const InputContainer = styled.div `
    width: inherit;
    margin-bottom: 24px;
`;
const FindAccountForm = ({ onSubmit }) => {
    const { t } = useTranslation();
    // change to react hook form after adding checking account on backend
    const [email, setEmail] = useState('');
    const handleInputChange = (ev) => {
        setEmail(ev.target.value);
    };
    const handleSubmit = () => {
        onSubmit(email);
    };
    return (_jsxs(FindAccountContainer, { children: [_jsx(InputContainer, { children: _jsx(Input, { InputLabelProps: { shrink: true }, label: t('auth.reset_password.steps.find_account.input_label', { ns: 'common' }), placeholder: t('auth.reset_password.steps.find_account.input_placeholder', { ns: 'common' }), value: email, onChange: handleInputChange }) }), _jsx(SubmitButton, { onClick: handleSubmit, children: t('auth.sign_up.steps.credentials.email.submit_btn_text', { ns: 'common' }) })] }));
};
export default FindAccountForm;
