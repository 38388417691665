var AuthenticationErrorCodes;
(function (AuthenticationErrorCodes) {
    AuthenticationErrorCodes["INVALID_CREDENTIALS"] = "auth.password_mismatch";
    AuthenticationErrorCodes["USER_NOT_FOUND"] = "auth.user_not_found";
    AuthenticationErrorCodes["EMAIL_IS_EXIST"] = "auth.email_already_exists";
})(AuthenticationErrorCodes || (AuthenticationErrorCodes = {}));
var ResetPasswordVariants;
(function (ResetPasswordVariants) {
    ResetPasswordVariants["EMAIL"] = "email";
})(ResetPasswordVariants || (ResetPasswordVariants = {}));
export { AuthenticationErrorCodes, ResetPasswordVariants };
